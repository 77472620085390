/* CoolAlert.css */
.alert-container {
    display: block;
    justify-content: center;
    align-items: center;
}

.alert-box {
    color: var(--black);
    padding: 0.5rem 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.dismiss-button {
    border: none;
    color: var(--black);
    font-size: 20px;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 50%;
    transition: background 0.3s ease;
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}

.alert-container.success .alert-box {
    background-color: #edf7ed;
    .dismiss-button {
        color: var(--success-main);
        &:hover {
            background: rgba(#2e7d32, 0.2);
        }
    }
}

.alert-container.error .alert-box {
    background-color: #fdeded;
    .dismiss-button {
        color: var(--error-main);
        &:hover {
            background: rgba(#d32f2f, 0.2);
        }
    }
}

.alert-container.warning .alert-box {
    background-color: #fff4e5;
    .dismiss-button {
        color: var(--warning-main);
        &:hover {
            background: rgba(#ed6c02, 0.2);
        }
    }
}

.alert-container.info .alert-box {
    background-color: #e5f6fd;
    .dismiss-button {
        color: var();
        &:hover {
            background: rgba(#0288d1, 0.2);
        }
    }
}

@keyframes slideDown {
    0% {
        transform: translateX(-50%) translateY(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
