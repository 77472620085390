@import '../../../styles/_designTokens.scss';

.common-avatar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .MuiAvatar-root {
        border: 0px solid $errorColor;
    }
}
.common-preview {
    img {
        width: 100%;
        height: auto;
    }
}
