@import './_colors.scss';
$defaultMargin: 8px;
$defaultFontSize: 16px;
$defaultFontWeight: normal;

.icon-container {
    display: flex;
    align-content: center; /* Center vertically */
    flex-direction: row;
    gap: 0.5rem;
    .icon {
        align-self: center;
        display: flex;
        flex-direction: row;
        svg {
            width: 1rem;
            height: 1rem;
        }
    }
}

.centered-avatar {
    display: grid;
    place-items: center;
}


